import { defineComponent } from 'vue';
import { patientenDebugStore } from '@/state/patientenDebugStore';
export default defineComponent({
  data: function data() {
    return {
      currentTab: 0
    };
  },
  computed: {
    selectedPatient: {
      get: function get() {
        return patientenDebugStore.state.selectedPatient;
      },
      set: function set(val) {
        patientenDebugStore.commit.setSelectedPatient(val);
        void patientenDebugStore.dispatch.loadLeistungen();
      }
    },
    patids: function patids() {
      return patientenDebugStore.state.patids;
    },
    isLoading: function isLoading() {
      return patientenDebugStore.state.isLoading;
    }
  },
  created: function created() {
    void patientenDebugStore.dispatch.loadPatids();
  }
});